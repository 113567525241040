import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/martonStyle.scss'
import { loading } from "./components/shared/loading";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";


const App = React.lazy(() => import('./App'));




ReactDOM.render(<Router>
  <Suspense fallback={loading()}>
    <App/>
  </Suspense>
</Router>, document.getElementById('root'));
serviceWorker.unregister();
