import React from 'react';
import Message from './Message'

const loading = (page = true) => {
  return (
    page ?
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center vh-100">
          <Message title="Loading..."/>
        </div>
      </div>
      :
      <Message title="Loading..."/>
  );
};

export { loading };
