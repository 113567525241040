import React, { Component } from 'react';

class Message extends Component {
  static defaultProps = {
    children: "",
    align: "center"
  };
  render() {
    const { title, align, header } = this.props;
    return (
      <React.Fragment>
        <div
          style={{ lineHeight: "30px" }}
          className={
            "ffbobby w-100 my-font-sz-2 text-uppercase mb-4 text-" + align
          }
        >
          {!header ? <div>{title}</div> : null}
          {header === "h1" && <h1>{title}</h1>}
          {header === "h2" && <h2>{title}</h2>}
          {header === "h3" && <h3>{title}</h3>}
          {header === "h4" && <h4>{title}</h4>}
        </div>
        {this.props.children !== "" && (
          <div
            style={{ lineHeight: "35px" }}
            className={"ffmontserrat my-font-sz-1-2 text-" + align}
          >
            {this.props.children}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Message;
